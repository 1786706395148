import { createSelector } from '@reduxjs/toolkit';
import { State } from 'app/reducers/index';
import * as fromMenuReducer from 'app/reducers/menu.reducer';
import { DisplayedGroup, GroupStructure, Product } from 'app/models/menu.model';

const selectSelf = (state: State): fromMenuReducer.MenuState => state[ fromMenuReducer.menuFeatureKey ];

export const selectSizeScales = createSelector(
  selectSelf,
  (state) => {
    return state.menu.sizeScales
  }
)

export const selectMenuOrganization = createSelector(
  selectSelf,
  (state) => {
    return state.organization;
  }
);

export const selectOverridePositionsMap = createSelector(
  selectSelf,
  (state) => {
    return state.overridePositionsMap;
  }
);

export const selectMenuSections = createSelector(
  selectSelf,
  (state) => {
    return state.menuSections;
  }
);

export const selectMenu = createSelector(
  selectSelf,
  (state) => {
    return state.menu;
  }
);

export const selectCurrency = createSelector(
  selectSelf,
  (state) => {
    return state.currency;
  }
);

export const selectMenuPending = createSelector(
  selectSelf,
  (state) => {
    return state.getMenusPending;
  }
);

export const selectDictionarySizes = createSelector(
  selectSelf,
  (state) => {
    return state.dictionarySizes;
  }
);

export const selectOriginalDictionarySizes = createSelector(
  selectSelf,
  (state) => {
    return state.originalDictionarySizes;
  }
);

export const selectDictionaryGroups = createSelector(
  selectSelf,
  (state) => {
    return state.dictionaryGroups;
  }
);

export const selectDictionaryGroupsAndOverridePositions = createSelector(
  selectSelf,
  (state) => {
    return {dictionaryGroups: state.dictionaryGroups, overridePositionsMap: state.overridePositionsMap};
  }
);

export const selectOriginalDictionaryGroups = createSelector(
  selectSelf,
  (state) => {
    return state.originalDictionaryGroups;
  }
);

export const selectDictionaryProducts = createSelector(
  selectSelf,
  (state) => {
    return state.dictionaryProducts;
  }
);

export const selectOriginalDictionaryProducts = createSelector(
  selectSelf,
  (state) => {
    return state.originalDictionaryProducts;
  }
);

export const selectFilteredDictionaryGroups = createSelector(
  selectSelf,
  (state) => {
    let result = new Map<string, DisplayedGroup>();

    for (let [key, group] of state.dictionaryGroups) {
      if (!state.searchQuery) {
        result.set(key, group);
        continue;
      } else if (group.name && group.name.toLowerCase().includes(state.searchQuery.toLowerCase())) {
        const filteredGroup = {...group, isFiltered: true};
        result.set(key, filteredGroup);
        continue;
      }

      let filteredGroup = {...group};
      filteredGroup.groupStructure = {
        productsInGroup: []
      } as GroupStructure;

      for (let productInGroup of group.groupStructure.productsInGroup) {
        const productInfo = state.dictionaryProducts.get(productInGroup.externalProductId);
        if (!filterProduct(productInfo, state.searchQuery)) {
          continue;
        }
        filteredGroup.groupStructure.productsInGroup.push(productInGroup);
      }

      if (!filteredGroup.groupStructure.productsInGroup.length) {
        continue;
      }

      result.set(key, filteredGroup);
    }

    return result;
  }
);

export const selectDictionaryModifierGroup = createSelector(
  selectSelf,
  (state) => {
    return state.dictionaryModifiersGroups;
  }
);

export const selectOriginalDictionaryModifierGroup = createSelector(
  selectSelf,
  (state) => {
    return state.originalDictionaryModifiersGroups;
  }
);

export const selectDictionaryComboGroup = createSelector(
  selectSelf,
  (state) => {
    return state.dictionaryComboGroup;
  }
);

export const selectOriginalDictionaryComboGroup = createSelector(
  selectSelf,
  (state) => {
    return state.originalDictionaryComboGroup;
  }
);

export const selectMenuSyncOfPOS = createSelector(
  selectSelf,
  (state) => {
    return state.menuSyncOfPOS;
  }
);

export const selectMenuSyncsOfSale = createSelector(
  selectSelf,
  (state) => {
    return state.menuSyncsOfSale;
  }
);

export const selectInfoForOverridePositions = createSelector(
  selectOverridePositionsMap,
  selectOriginalDictionaryGroups,
  selectOriginalDictionaryProducts,
  selectOriginalDictionarySizes,
  selectOriginalDictionaryModifierGroup,
  selectOriginalDictionaryComboGroup,
  (overridePositionsMap, originalDictionaryGroups, originalDictionaryProducts, originalDictionarySizes, originalDictionaryModifierGroup, originalDictionaryComboGroup) => {
    return {
      overridePositionsMap,
      originalDictionaryGroups,
      originalDictionaryProducts,
      originalDictionarySizes,
      originalDictionaryModifierGroup,
      originalDictionaryComboGroup
    };
  }
);

export const selectLoadingUploadingState = createSelector(
  selectSelf,
  (state) => {
    return (pipeId: string) => state.uploadsState.menuSyncLoadings[ pipeId ];
  }
);

export const selectErrorUploadingState = createSelector(
  selectSelf,
  (state) => {
    return (pipeId: string) => state.uploadsState.menuSyncErrors[ pipeId ];
  }
);

export const selectMenuSyncsOfSaleByMenu = createSelector(
  selectSelf,
  (state) => {
    return (menuId: string) => state.menuSyncsOfSale.filter(m => m.menuId === menuId);
  }
);

const filterProduct = (product: Product, query: string): boolean => {
  if (!product){
    return false;
  }
  return product.name && product.name.toLowerCase().includes(query.toLowerCase())
    || product.sku && product.sku.includes(query)
    || product.description && product.description.toLowerCase().includes(query.toLowerCase());
}

export const selectSearchQuery = createSelector(
  selectSelf,
  (state) => {
    return state.searchQuery;
  }
);

export const selectForMenuMainContentComponent = createSelector(
  selectFilteredDictionaryGroups,
  selectOriginalDictionaryGroups,
  selectDictionaryProducts,
  selectOriginalDictionaryProducts,
  selectDictionaryModifierGroup,
  selectOriginalDictionaryModifierGroup,
  selectDictionaryComboGroup,
  selectOriginalDictionaryComboGroup,
  selectDictionarySizes,
  selectOriginalDictionarySizes,
  selectCurrency,
  selectSearchQuery,
  selectOverridePositionsMap,
  (groupMap,
   originalGroupMap,
   products,
   originalProducts,
   modifierGroups,
   originalModifierGroups,
   comboGroups,
   originalComboGroups,
   sizes,
   originalSizes,
   currency,
   searchQuery,
   overridePositionsMap) => {
    return {
      groupMap,
      originalGroupMap,
      products,
      originalProducts,
      modifierGroups,
      originalModifierGroups,
      comboGroups,
      originalComboGroups,
      sizes,
      originalSizes,
      currency,
      searchQuery,
      overridePositionsMap,
    };
  }
);
