import { Component, Input, OnChanges } from '@angular/core';
import { Store } from '@ngxs/store';
import { IBaseResponse } from 'app/models/baseResponse.model';
import { BucketName } from 'app/models/bucketName';
import { DetailedOrderInQueue } from 'app/models/orderInQueue.model';
import { APIService } from 'app/services/api.service';
import { DatePipe } from "@angular/common";
import { getElasticSearchLogUrl } from "app/helpers/elastic-search/elastic-search-helper";
import { ElasticSearchScopeFields } from "app/helpers/elastic-search/elastic-search-scope-fields";
import { gecoAnalyticIndexId, hubIndexId } from "app/helpers/elastic-search/elastic-search-constants";

@Component({
  selector: 'app-order-system-logs',
  templateUrl: './order-system-logs.component.html',
  styleUrls: ['./order-system-logs.component.scss'],
})
export class OrderSystemLogsComponent implements OnChanges{
  @Input() currentOrder: DetailedOrderInQueue;
  viewElasticSearchLogUrl: boolean;
  hubElasticSearchLogUrl: string;
  ltElasticSearchLogUrl: string;
  constructor(public store: Store, private api: APIService,private datePipe: DatePipe) {
  }

  downloadFile(bucketKey: string): void {
    this.api.getUrlFromBucket(bucketKey, BucketName.OrderHistory).subscribe({
      next: (res: IBaseResponse<string>) => {
        if (res) {
          const url = res.data;
          const downloadLink = document.createElement('a');
          downloadLink.href = url;
          downloadLink.target = '_blank';
          document.body.appendChild(downloadLink);
          downloadLink.click();
          downloadLink.parentNode.removeChild(downloadLink);
        }
      },
      error: (e) => {
        console.log(e);
        alert('Can\'t download file');
      }
    });
  }

  ngOnChanges(): void {
    this.viewElasticSearchLogUrl = this.currentOrder && this.currentOrder.sourceSystem === 34 && this.currentOrder.targetSystem === 32; //INTERNALHUB => GECOLOCATION
    const elasticFrom = this.currentOrder.discoveredAt;
    const elasticTo = this.datePipe.transform(new Date(this.currentOrder.discoveredAt).getTime()+ 4 * 60 * 60 * 1000, 'yyyy-MM-ddTHH:mm:ss'); // add 4 hours
    this.hubElasticSearchLogUrl = getElasticSearchLogUrl(
      hubIndexId,
      elasticFrom,
      elasticTo,
      [
        {key: ElasticSearchScopeFields.TrackOrderId, value: this.currentOrder.sourceOrderId},
        {key: ElasticSearchScopeFields.CorrelationId, value: this.currentOrder.id}]);
    this.ltElasticSearchLogUrl = getElasticSearchLogUrl(
      gecoAnalyticIndexId,
      elasticFrom,
      elasticTo,
      [{key: ElasticSearchScopeFields.TrackOrderId, value: this.currentOrder.sourceOrderId}]);
  }
}
