import { OrderTags } from "app/models/orderInQueue.model";

export interface OrderFilter {
    orderNumber?: string;
    orderStatus?: number;
    sourceSystemType?: number;
    targetSystemType?: number;
    organizationIds: string[];
    from?: Date;
    to?: Date;
    skip: number;
    take: number;
    sortColumn?: string;
    sortDirection?: string;
    tag?: OrderTags;
}

export const NoOrdersId = 'no-orders';
