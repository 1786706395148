/**
 * Важно!!! Путь должен начинаться с '/' или 'https://'
 * Важно!!! Путь не должен заканчиваться '/'
 * http://domain.name - правильно
 * http://domain.name/uri - правильно
 * http://domain.name/uri/ - НЕ правильно
 * /uri/ - НЕ правильно
 * /uri - правильно
 */
export const environment = {
    production: true,
    hmr: false,
    baseUrl: 'https://kiosk.getit.rest',
    env: 'production-ru',
    gMapApiKey: 'AIzaSyCz5iS1OOsPRFifhODTGm9elD84e-aMN90',
    posBaseUrl: 'https://api-kiosk.getit.rest',
    livetrackingBaseUrl: 'https://api-track.ru.ge-co.io',
    widgetBaseUrl: 'https://widget.staging.open-s.info',
    kioskBaseUrl : 'https://kiosk-staging.open-s.info',
    lastHashCommit : 'f793b46d7c1fb86a8b21b21c543c10527de7a40b'
};
