import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ChangeDetectionStrategy, Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { Observable } from 'rxjs';
import { DetailedOrderInQueue, OrderInQueue } from 'app/models/orderInQueue.model';
import { OrderFilter } from 'app/models/orderFilter.model';
import { Select, Store } from '@ngxs/store';
import { FiltersState } from 'app/state-management/states/filters.states';
import { ActivatedRoute, Router } from '@angular/router';
import { OrdersState } from 'app/state-management/states/orders.states';
import { OrganizationsState } from 'app/state-management/states/organizations.states';
import { PipeConfiguration } from 'app/models/pipe.model';
import { Organization } from 'app/models/organization.model';
import { MatTabGroup } from '@angular/material/tabs';

@UntilDestroy()
@Component({
  selector: 'app-order-list',
  templateUrl: './order-list.component.html',
  styleUrls: ['./order-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
  encapsulation: ViewEncapsulation.None
})
export class OrderListComponent implements OnInit {
  selectedIndex: number;
  organization: Organization;
  isLoading = true;

  orders: OrderInQueue[];
  ordersCount: number;
  selectedOrder: DetailedOrderInQueue | null = null;

  @Select(FiltersState.getOrderFilter) getOrderFilter$: Observable<OrderFilter>;
  @Select(state => state.orders.ordersList) orders$: Observable<OrderInQueue[]>;
  @Select(state => state.orders.ordersListCount) ordersCount$: Observable<number>;
  @Select(OrdersState.currentOrder) currentOrder$: Observable<DetailedOrderInQueue>;
  @Select(OrganizationsState.pipes) pipes$: Observable<PipeConfiguration[]>;
  @Select(OrganizationsState.getBranches) organizations$: Observable<any[]>;
  @Select(OrganizationsState.getSelectedOrganization) organization$: Observable<Organization>;
  @Select(OrganizationsState.getSelectedOrganizationId) getSelectedOrganizationId$: Observable<string>;
  @ViewChild(MatTabGroup) tabGroup: MatTabGroup;

  constructor(
    public router: Router,
    public store: Store,
    public activatedRoute: ActivatedRoute,
  ) {
  }

  ngOnInit(): void {
    this.currentOrder$
      .pipe(
        untilDestroyed(this)
      )
      .subscribe(currentOrder => {
        this.selectedOrder = currentOrder;
        if (currentOrder && currentOrder.details) {
          this.isLoading = false;
        } else {
          this.isLoading = true;
        }
      });

    this.selectedIndex = 0;
    this.organization$.pipe(
      untilDestroyed(this)
    ).subscribe(organization => {
      this.organization = organization;
    });

    this.getOrderFilter$.pipe(
      untilDestroyed(this)
    ).subscribe(_ => this.selectedOrder = null);
  }
}

