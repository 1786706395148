<div class="header">

  <h1 class="header-title">{{ 'ORDERS.ORDERS' | translate }}</h1>
  <app-order-filter></app-order-filter>

</div>

<div class="orders">

  <div class="card-container">

    <h3 class="details-count">{{ ordersCount$ | async }} {{ 'ORDERS.ORDERS' | translate | lowercase }}</h3>
    <ul class="order-list">
      <ng-container *ngFor="let order of orders$ | async; let i = index">
        <app-order-list-item
          [currentOrder]="currentOrder$ | async"
          [order]="order"
          [selected]="(currentOrder$ | async) ? order.id === (currentOrder$ | async).id : i === 0"
        ></app-order-list-item>
      </ng-container>
    </ul>

  </div>
  <mat-progress-bar *ngIf="isLoading; else noloader" color="accent" mode="indeterminate"></mat-progress-bar>
  <ng-template #noloader>
    <div *ngIf="(ordersCount$ | async) > 0; else noOrders" style="width: 100%;">
      <app-order-list-details></app-order-list-details>
    </div>
    <ng-template #noOrders>
      <div style="padding: 24px 40px;">
        {{"ORDERS.ORDERS_NOT_FOUND" | translate}}
      </div>
    </ng-template>
  </ng-template>

</div>
