import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { IBaseResponse } from 'app/models/baseResponse.model';
import { environment } from 'environments/environment';
import {
  GetComboProductStructuresRequestDto, GetComboProductStructuresResponseItemDto
} from 'app/models/getComboProductStructures.model';


@Injectable({
  providedIn: 'root',
})
export class ProductApiService {
  // http options used for making API calls
  private headers: HttpHeaders = new HttpHeaders({
    'Content-Type': 'application/json',
  });

  constructor(private http: HttpClient) {
  }

  public getExternalProductIdsByModifierGroup(menuId: string, modifierGroupId: string, onlyCombo: boolean)
    : Promise<IBaseResponse<string[]>> {
    const url = `${environment.posBaseUrl}/api/front/product/list/${menuId}/${modifierGroupId}`;
    return firstValueFrom(this.http.get<IBaseResponse<string[]>>(
      url,
      {
        headers: this.headers,
        params: {
          onlyCombo: onlyCombo,
        },
      }));
  }

  public getAllComboProductStructures(request: GetComboProductStructuresRequestDto)
  {
    const url = `${environment.posBaseUrl}/api/front/product/getAllComboProductStructures`;
    return firstValueFrom(
      this.http.post<IBaseResponse<GetComboProductStructuresResponseItemDto[]>>(
        url,
        request,
        {
          headers: this.headers,
        })
    );
  }
}
