import { Component, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { DetailedOrderInQueue } from 'app/models/orderInQueue.model';
import { Organization } from 'app/models/organization.model';
import { OrganizationsState } from 'app/state-management/states/organizations.states';
import { firstValueFrom } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { OrdersState } from 'app/state-management/states/orders.states';
import { Clipboard } from '@angular/cdk/clipboard';
import { SnackbarService } from 'app/services/snackbar.service';
import { TranslateService } from '@ngx-translate/core';
import { OrderFilter } from 'app/models/orderFilter.model';
import { RoleMembership, selectRole } from 'app/reducers/context.selectors';
import { Store as ngrxStore } from '@ngrx/store';
import { ConfigurationType, IExternalSystem } from 'app/models/externalSystem.model';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-order-list-details',
  templateUrl: './order-list-details.component.html',
  styleUrls: ['./order-list-details.component.scss']
})
export class OrderListDetailsComponent implements OnInit {
  currentOrder: DetailedOrderInQueue = null;
  currentOrderOrganization: Organization;
  roleMembership: RoleMembership;
  externalSystems: IExternalSystem[];

  showDeliveryDetails: boolean;

  constructor(
    public store: Store,
    public ngrxStore: ngrxStore,
    private router: Router,
    private clipboard: Clipboard,
    private snackbarService: SnackbarService,
    public translate: TranslateService,
    public activatedRoute: ActivatedRoute
  ) {
  }

  async ngOnInit(): Promise<void> {
    const [organizations, roleMembership, externalSystems] = await Promise.all([
      firstValueFrom(this.store.select(OrganizationsState.getBranches)),
      firstValueFrom(this.ngrxStore.select(selectRole)),
      firstValueFrom(this.store.select(OrganizationsState.getExternalSystems)),
    ]);
    this.roleMembership = roleMembership;
    this.externalSystems = externalSystems;

    this.store.select(OrdersState.currentOrder)
      .pipe(
        untilDestroyed(this),
      ).subscribe(currentOrder => {
        this.currentOrder = currentOrder;
        this.showDeliveryDetails =
          currentOrder
          && this.externalSystems.find(x => x.key === currentOrder.targetSystem)?.params.configurationType === ConfigurationType.POS
          && !currentOrder?.details?.deliveryInfo?.isTakeAway
          && !currentOrder?.metadata?.orderInfoDto?.isGoOrder;
        this.currentOrderOrganization = organizations.find(org => org.id === currentOrder.organizationId);
    });
  }

  copyLink(): void {
    const protocol = window.location.protocol;
    const host = window.location.host;
    if (this.currentOrder && this.currentOrderOrganization) {
      this.clipboard.copy(`${protocol}//${host}/app/${this.currentOrderOrganization.id}/order/details/${this.currentOrder.id}`);
      this.snackbarService.success(this.translate.instant('COMMON.LINK_COPIED_SUCCESSFULLY'));
    } else {
      this.snackbarService.error(this.translate.instant('ORDERS.ORDER_NOT_FOUND'));
    }
  }

  showLinkedOrderInQueues(
    organizationId: string,
    orderInQueueId: string,
    masterOrderId: string,
    discoveredAt: Date){
    const urlTree = this.router.createUrlTree(
      ['app', organizationId, 'order', 'list', orderInQueueId],
      {
        queryParams: {
          organizationIds: [organizationId],
          orderNumber: masterOrderId,
          from: this.addHours(discoveredAt, -24),
          to: this.addHours(discoveredAt, 24),
        } as OrderFilter
      })

    const link = this.router.serializeUrl(urlTree);

    window.open(link, '_blank');
  }

  addHours(date: Date, hours: number): Date {
    let result = new Date(date);
    result.setHours(result.getHours() + hours);
    return result;
  }

}
