import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { Store } from '@ngxs/store';
import { DetailedOrderInQueue, OrderInQueue } from 'app/models/orderInQueue.model';
import { OrganizationsState } from 'app/state-management/states/organizations.states';
import { firstValueFrom } from 'rxjs';
import { IExternalSystem } from 'app/models/externalSystem.model';

@Component({
  selector: 'app-order-list-item',
  templateUrl: './order-list-item.component.html',
  styleUrls: ['./order-list-item.component.scss'],
})
export class OrderListItemComponent implements OnInit {

  @Input() order: OrderInQueue;
  @Input() currentOrder: DetailedOrderInQueue;
  @Input() className = 'card';
  @Input() selected = false;

  externalSystems: IExternalSystem[];
  locale: string;

  constructor(
    public store: Store,
    public translateService: TranslateService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {
    translateService.onLangChange.subscribe((locale: LangChangeEvent) => {
      this.locale = locale.lang;
    });
  }

  ngOnInit(): void {
    this.externalSystems = this.store.selectSnapshot<IExternalSystem[]>(OrganizationsState.getExternalSystems);
  }

  async orderClick(order: OrderInQueue): Promise<void> {
    const [qParams, organizationId] = await Promise.all([
      firstValueFrom(this.activatedRoute.queryParams),
      firstValueFrom(this.store.select(OrganizationsState.getSelectedOrganizationId)),
    ])

    await this.router.navigate(['/app', organizationId, 'order', 'list', order.id], {
      queryParams: qParams,
      replaceUrl: true,
    });
  }
}
