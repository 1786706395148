import { Pipe, PipeTransform } from '@angular/core';
import { DayOfWeek} from 'app/models/organization.model';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'weekPipe'
})
export class WeekPipe implements PipeTransform {
  constructor(
    private translateService: TranslateService) {
  }
  transform(value: DayOfWeek, ...args: unknown[]): string {
    let result = '';
    switch (value){
      case 0:
        result = 'COMMON.SUNDAY';
        break;
      case 1:
        result = 'COMMON.MONDAY';
        break;
      case 2:
        result = 'COMMON.TUESDAY';
        break;
      case 3:
        result = 'COMMON.WEDNESDAY';
        break;
      case 4:
        result = 'COMMON.THURSDAY';
        break;
      case 5:
        result = 'COMMON.FRIDAYS';
        break;
      case 6:
        result = 'COMMON.SATURDAY';
        break;
    }

    return this.translateService.instant(result);
  }
}
