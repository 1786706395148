import { Component, OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Store as ngrxStore } from '@ngrx/store';
import { DetailedOrderInQueue } from 'app/models/orderInQueue.model';
import { Organization } from 'app/models/organization.model';
import { APIService } from 'app/services/api.service';
import { OrganizationsState } from 'app/state-management/states/organizations.states';
import { map, Observable, take } from 'rxjs';
import { IExternalSystem } from 'app/models/externalSystem.model';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { IBaseResponse } from 'app/models/baseResponse.model';
import { OrdersState } from 'app/state-management/states/orders.states';
import { RoleMembership, selectRole } from "app/reducers/context.selectors";

@UntilDestroy()
@Component({
  selector: 'app-order-details',
  templateUrl: './order-details.component.html',
  styleUrls: ['./order-details.component.scss']
})
export class OrderDetailsComponent implements OnInit {

  @Select(OrdersState.currentOrder) currentOrder$: Observable<DetailedOrderInQueue>;
  order: DetailedOrderInQueue;

  externalSystems: IExternalSystem[];

  public roleMembership: RoleMembership;

  @Select(OrganizationsState.getBranches) organizations$: Observable<Organization[]>;

  constructor(
    private store: Store,
    private ngrxStore: ngrxStore,
    private api: APIService) {
  }

  ngOnInit(): void {
    this.currentOrder$
      .pipe(untilDestroyed(this))
      .subscribe(currentOrder => {
        this.order = currentOrder;
      });
    this.externalSystems = this.store.selectSnapshot<IExternalSystem[]>(OrganizationsState.getExternalSystems);
    this.ngrxStore.select(selectRole)
      .pipe(
        untilDestroyed(this)
      )
      .subscribe(roleMembership => {
        this.roleMembership = roleMembership
      });
  }

  getOrganizationBranchName$(): Observable<string> {
    return this.organizations$
      .pipe(
        take(1),
        map(organizations => organizations.find(org => org.id === this.order?.organizationId)?.name));
  }

  downloadMenu(menuId: string, isSource: boolean): void {
    this.api.getMenuUrlById(menuId, isSource).subscribe((res: IBaseResponse<string>) => {
      if (res) {
        const downloadLink = document.createElement('a');
        downloadLink.href = res.data;
        downloadLink.download = res.data;
        downloadLink.target = '_blank';
        document.body.appendChild(downloadLink);
        downloadLink.click();
        downloadLink.parentNode.removeChild(downloadLink);
      }
    });
  }
}
