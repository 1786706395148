import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse, } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { APIService } from 'app/services/api.service';
import { environment } from 'environments/environment';
import { Store } from "@ngrx/store";
import { selectTokenInfo } from "app/reducers/context.selectors";
import { TokenInfo } from "app/reducers/context.reducer";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { refreshLoginData, signOutUser } from "app/reducers/context.actions";

const Unauthenticated = "Unauthenticated"

@Injectable({
  providedIn: 'root',
})
@UntilDestroy()
export class TokenInterceptorService implements HttpInterceptor {
  private tokenInfo: TokenInfo | null = null;

  constructor(
    private apiService: APIService,
    private store: Store,
  ) {
    this.store.select(selectTokenInfo).pipe(
      untilDestroyed(this)
    ).subscribe(tokenInfo => {
      this.tokenInfo = tokenInfo
    })
  }

  static addToken(req: HttpRequest<any>, token: string): HttpRequest<any> {
    return req.clone({setHeaders: {Authorization: `Bearer ${token}`}});
  }

  intercept(
    req: HttpRequest<object>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if ((req.url.includes(environment.baseUrl) || req.url.includes(environment.posBaseUrl) || req.url.includes(environment.kioskBaseUrl))
      && req.url.indexOf('api/signal') === -1)
    {
      if (this.tokenInfo) {
        req = TokenInterceptorService.addToken(req, this.tokenInfo.token);
      }
    }

    return next.handle(req).pipe(
      switchMap((event) => {
        if (event instanceof HttpResponse) {
          const body = event.body;

          if (body.hasOwnProperty('jsonrpc') && body.jsonrpc === '2.0') {
            // -32003 - User didn't find
            if (
              body.hasOwnProperty('error') && (body.error.code === -32600 || body.error.code === -32003)
            ) {
              if (this.tokenInfo && this.tokenInfo.refreshToken) {
                return this.apiService.refreshToken({
                  refreshToken: this.tokenInfo.refreshToken
                }).pipe(
                  switchMap(loginResponse => {
                    // did we get a new token retry previous request
                    if (loginResponse && loginResponse.data.refreshToken) {
                      const newToken = loginResponse.data.authToken;
                      const tokenParts = this.apiService.decodeToken(loginResponse.data.authToken);

                      this.store.dispatch(refreshLoginData({
                        tokenInfo: {
                          token: loginResponse.data.authToken,
                          refreshToken: loginResponse.data.refreshToken,
                          tokenExpires: tokenParts.tokenExpires,
                        },
                        userData: loginResponse.data.userData
                      }));
                      return next.handle(TokenInterceptorService.addToken(req, newToken));
                    } else {
                      // If we don't get a new token, we are in trouble so logout.
                      throw new Error(Unauthenticated)
                    }
                  }),
                  catchError(err => {
                    throw new Error(Unauthenticated)
                  })
                )
              } else {
                throw new Error(Unauthenticated)
              }
            }
          }
        }
        return of(event);
      }),
      catchError((err, event) => {
        if (err.message == Unauthenticated) {
          this.store.dispatch(signOutUser());
        }
        throw err;
      })
    );
  }
}
