  <div class="details" *ngIf="currentOrder; else noorder">
    <mat-progress-bar mode="indeterminate" color="accent" *ngIf="currentOrder.isLoading; else noloader"></mat-progress-bar>
    <ng-template #noloader>
      <mat-tab-group #tabGroup color="accent">
            <mat-tab [label]="'ORDERS.SUMMARY' | translate">
              <div *ngIf="currentOrder.metadata?.tags?.length" class="tags">
                <div class="tag-title">{{ 'ORDERS.TAGS' | translate }}:</div>
                <ng-container *ngFor="let tag of currentOrder.metadata.tags">
                  <app-tag [name]="translate.instant('TAGS.' + tag)"></app-tag>
                </ng-container>
              </div>
              <div class="summary" *ngIf="currentOrder">
                  <div *ngIf="currentOrder.details">
                      <h3 class="details-header" mat-header>{{ 'ORDERS.ORDERED' | translate }}</h3>
                      <div *ngFor="let item of currentOrder.details.items; let i = index"
                            style="display: flex; justify-content: flex-start; align-items: stretch;">
                        <div class="product-number">{{(+i) + 1}}.</div>
                        <div style="flex-grow: 1;">
                              <div class="product">
                                <div mat-line class="product-name bold">{{item.name | join:item.sizeName}}</div>
                                <div mat-line class="product-details" *ngIf="item.price">
                                    <span class="quantity">{{item.quantity}}&nbsp;</span>
                                    <mat-icon class="clear">clear</mat-icon>
                                    <span class="price">&nbsp;{{item.price | number:'.2'}}</span>
                                </div>
                              </div>

                              <ng-container *ngFor="let group of item.groups">
                                <div class="product">
                                  <div mat-line class="product-name">{{group.name}}</div>
                                  <div mat-line class="product-details"></div>
                                </div>
                              </ng-container>

                              <ng-container *ngFor="let mod of item.modifierGroups">
                                <div class="product" *ngFor="let modifier of mod.modifiers">
                                  <div mat-line class="product-name">{{modifier.name}}</div>
                                  <div mat-line class="product-details" *ngIf="modifier.price">
                                    <span class="quantity">{{modifier?.quantity}}&nbsp;</span>
                                    <mat-icon class="clear" *ngIf="modifier.quantity && modifier.price">clear</mat-icon>
                                    <span class="price">&nbsp;{{modifier?.price | number:'.2'}}</span>
                                  </div>
                                </div>
                              </ng-container>

                          </div>
                      </div>

                      <div *ngIf="currentOrder.details.deliveryCharge">
                        <div class="product-number">&nbsp;</div>
                        <div style="flex-grow: 1;">
                          <div class="product">
                            <div mat-line class="product-name bold">{{'ORDERS.DELIVERY_CHARGE' | translate}}</div>
                            <div mat-line class="product-details">
                              <span class="price">&nbsp;{{currentOrder.details.deliveryCharge | number:'.2'}}</span>
                            </div>
                          </div>
                        </div>
                      </div>

                      <ng-container *ngIf="currentOrder.details.discounts && currentOrder.details.discounts.length">
                        <h4 class="details-header" mat-header>{{ 'ORDERS.DISCOUNTS' | translate }}</h4>
                        <div *ngFor="let item of (currentOrder.details.discounts); let i = index"
                              style="display: flex; justify-content: flex-start; align-items: stretch;">
                          <div style="flex-grow: 1;">
                            <div class="product">
                              <div mat-line class="product-name bold">{{item.name || item.type || ('ORDERS.DISCOUNTS' | translate)}}</div>
                              <div mat-line class="product-details" *ngIf="item.discountValue">
                                <span class="price">&nbsp;{{item.discountValue | number:'.2'}}  {{currentOrderOrganization?.currency}}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="currentOrder.details?.promoCode" style="flex-grow: 1;">
                            <div class="product">
                                <div mat-line class="product-name bold">{{'ORDERS.PROMO_CODE' | translate}}</div>
                                <div mat-line class="product-details">
                                    <span class="price">{{currentOrder.details?.promoCode}}</span>
                                </div>
                            </div>
                        </div>
                      </ng-container>

                      <div class="total">
                        {{ 'ORDERS.TOTAL' | translate }}
                        <span class="total-cost">{{currentOrder?.details?.sum | number:'.2'}} {{currentOrderOrganization?.currency}}</span>
                      </div>

                      <ng-container *ngIf="currentOrder.details.comment">
                        <div class="details-comment">
                          <div class="details-comment-label">{{'ORDERS.ORDER_COMMENT' | translate }}</div>
                          {{currentOrder.details.comment}}
                        </div>
                      </ng-container>

                      <app-order-payment [payments]="currentOrder.details.payments"></app-order-payment>
                  </div>

                  <div>
                    <app-order-delivering></app-order-delivering>
                    <app-order-details></app-order-details>
                    <button
                      (click)="showLinkedOrderInQueues(this.currentOrder.organizationId, this.currentOrder.id, this.currentOrder.masterOrderId, this.currentOrder.discoveredAt)"
                    >
                      <img src="/assets/icons/link.svg" alt="">
                      {{ 'ORDERS.SHOW_LINKED' | translate }}
                    </button>
                  </div>

                  <div>
                    <app-order-status-history [statusHistory]="currentOrder.statusHistory"></app-order-status-history>
                  </div>
              </div>
            </mat-tab>

            <mat-tab [label]="'ORDERS.SYSTEM_LOGS' | translate" *ngIf="roleMembership.isSupport">
              <ng-template matTabContent>
                <app-order-system-logs [currentOrder]="currentOrder"></app-order-system-logs>
              </ng-template>
            </mat-tab>

            <mat-tab *ngIf="showDeliveryDetails">
              <ng-template mat-tab-label>
                <span class="mat-ripple mat-tab-label mat-focus-indicator">{{'ORDERS.DELIVERY' | translate}}</span>
              </ng-template>

              <app-calculate-delivering></app-calculate-delivering>

            </mat-tab>

      </mat-tab-group>
      <button
        class="copy"
        (click)="copyLink()"
      >
        <img src="/assets/icons/link.svg" alt="">
        {{ 'ORDERS.COPY_LINK' | translate }}
      </button>
    </ng-template>
  </div>
  <ng-template #noorder>
    <div style="padding: 24px;">
      {{"ORDERS.ORDER" | translate}} <br>
      {{"ORDERS.ID" | translate}} {{(activatedRoute.params | async).id}} <br>
      {{"ORDERS.ORDER_NOT_FOUND" | translate}} <br>
    </div>
  </ng-template>
