import { Injectable } from '@angular/core';
import { Action, NgxsOnInit, Selector, State, StateContext, Store } from '@ngxs/store';
import { patch, updateItem } from '@ngxs/store/operators';
import { DetailedOrderInQueue, OrderInQueue } from 'app/models/orderInQueue.model';
import { APIService } from 'app/services/api.service';
import {
  IncreaseOrderCount,
  MarkViewOrders,
  SetCurrentOrder,
  SetDeliveringCalculates,
  SetDeliveryHistory,
  SetOrders,
  SetOrdersList,
  UpdateOrder
} from '../actions/orders.actions';
import { catchError, debounceTime, map, switchMap, take } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { OrderStatus } from 'app/models/orderStatus.model';
import { OrderStatusService } from 'app/services/order-status.service';
import { NoOrdersId, OrderFilter } from 'app/models/orderFilter.model';
import { ICalculateDeliveryModel } from 'app/models/ICalculateDeliveryModel';
import { GlobalStateModel } from 'app/models/globalState.model';

export class OrderStateModel {
  notViewNewOrdersCount: number;
  lastViewDate: Date;
  orders: OrderInQueue[];
  ordersList: OrderInQueue[];
  currentOrder: DetailedOrderInQueue | null;
  ordersCount: number;
  masterOrdersCount: number;
  ordersListCount: number;
  deliveryCalculates: ICalculateDeliveryModel[];
}

@State<OrderStateModel>({
  name: 'orders',
  defaults: {
    notViewNewOrdersCount: 0,
    lastViewDate: new Date(),
    orders: [],
    ordersList: [],
    currentOrder: null,
    ordersCount: 0,
    masterOrdersCount: 0,
    ordersListCount: 0,
    deliveryCalculates: new Array<ICalculateDeliveryModel>(),
  },
})

@Injectable()
export class OrdersState implements NgxsOnInit {
  constructor(private apiProvider: APIService, private store: Store) {
  }

  @Selector()
  static getNotViewNewOrdersCount(state: OrderStateModel): number {
    return state.notViewNewOrdersCount;
  }

  @Selector()
  static getLastViewDate(state: OrderStateModel): Date {
    return state.lastViewDate;
  }

  @Selector()
  static orders(state: OrderStateModel): OrderInQueue[] {
    return state.orders;
  }

  @Selector()
  static ordersList(state: OrderStateModel): OrderInQueue[] {
    return state.ordersList;
  }

  @Selector()
  static activeOrders(state: OrderStateModel): OrderInQueue[] {
    return state.orders.filter(order => {
      return !(
        ((order.orderStatus & OrderStatus.CLOSED) !== 0) ||
        ((order.orderStatus & OrderStatus.CANCELLED) !== 0)
      );
    });
  }

  @Selector()
  static deliveryCalculates(state: OrderStateModel): ICalculateDeliveryModel[] {
    return state.deliveryCalculates;
  }

  @Selector()
  static failedOrders(state: OrderStateModel): OrderInQueue[] {
    return state.orders.filter(value => {
      return (
        ((value.orderStatus & OrderStatus.CONVERSION_ERROR) !== 0) ||
        ((value.orderStatus & OrderStatus.GIVEN_UP) !== 0) ||
        ((value.orderStatus & OrderStatus.SOURCE_SYNC_ERROR) !== 0) ||
        ((value.orderStatus & OrderStatus.TARGET_SYNC_ERROR) !== 0) ||
        ((value.orderStatus & OrderStatus.IGNORED) !== 0)
      );
    });
  }

  @Selector()
  static ordersCount(state: OrderStateModel): number {
    return state.ordersCount;
  }

  @Selector()
  static masterOrdersCount(state: OrderStateModel): number {
    return state.masterOrdersCount;
  }

  @Selector()
  static ordersListCount(state: OrderStateModel): number {
    return state.ordersListCount;
  }

  @Selector()
  static currentOrder(state: OrderStateModel): DetailedOrderInQueue {
    return state.currentOrder;
  }

  ngxsOnInit({getState, patchState}: StateContext<OrderStateModel>): void {
  }

  @Action(IncreaseOrderCount)
  increaseOrderCount({getState, patchState}: StateContext<OrderStateModel>): OrderStateModel {
    const state = getState();
    return patchState({notViewNewOrdersCount: state.notViewNewOrdersCount + 1});
  }

  @Action(MarkViewOrders)
  markViewOrders({patchState}: StateContext<OrderStateModel>): OrderStateModel {
    return patchState({notViewNewOrdersCount: 0, lastViewDate: new Date()});
  }

  @Action(SetOrders)
  setOrders({patchState}: StateContext<any>, {filter}: { filter: OrderFilter }): Observable<GlobalStateModel> {
    return this.apiProvider.fetchOrders(filter)
      .pipe(
        debounceTime(3000),
        map(res => {
          return patchState({
            orders: res.data.items.map(order => this.convertOrder(order)),
            ordersCount: res.data.totalItems
          });
        })
      );
  }

  @Action(SetOrdersList)
  setOrdersList({patchState}: StateContext<any>, {filter}: { filter: OrderFilter }): Observable<GlobalStateModel> {
    return this.apiProvider.fetchOrders(filter)
      .pipe(
        take(1),
        map(res => {
          return patchState({
            ordersList: res.data.items.map(order => this.convertOrder(order)),
            ordersListCount: res.data.totalItems
          });
        })
      );
  }

  convertOrder(order: OrderInQueue) {
    if (!order.metadata.tags) {
      order.metadata.tags = [];
    }
    return {
      ...order,
      discoveredAtUTC: (new Date(order.discoveredAt)).getTime() - (new Date()).getTimezoneOffset() * 60000,
      details: order.metadata.orderInfoDto,
      orderStatusInfo: OrderStatusService.getStatusInfo(order.orderStatus)
    };
  }

  @Action(UpdateOrder)
  updateOrder(ctx: StateContext<OrderStateModel>, {orderInQueue}: { orderInQueue: OrderInQueue }): OrderStateModel {
    const state = ctx.getState();
    const existingOrder = state.orders.find(order => order.id === orderInQueue.id);
    const newOrder = {
      ...orderInQueue,
      details: orderInQueue.metadata.orderInfoDto,
      orderStatusInfo: OrderStatusService.getStatusInfo(orderInQueue.orderStatus)
    };
    if (existingOrder) {
      return ctx.setState(
        patch({
          orders: updateItem<OrderInQueue>(order => order.id === existingOrder.id, {...newOrder}),
          currentOrder: state.currentOrder && state.currentOrder.id === orderInQueue.id
            ? {...state.currentOrder, ...newOrder} : state.currentOrder
        })
      );
    } else {
      return ctx.patchState(
        {
          orders: [{
            ...orderInQueue,
            orderStatusInfo: OrderStatusService.getStatusInfo(orderInQueue.orderStatus)
          }, ...state.orders],
          currentOrder: state.currentOrder && state.currentOrder.id === orderInQueue.id ? {
            ...state.currentOrder, ...newOrder, details: newOrder.metadata.orderInfoDto
          } : state.currentOrder
        }
      );
    }
  }

  @Action(SetCurrentOrder)
  setCurrentOrder(ctx: StateContext<OrderStateModel>, {orderId}: SetCurrentOrder)
    : Observable<DetailedOrderInQueue> {

    const orders = this.store.selectSnapshot(OrdersState.ordersList);
    let detailedOrder: DetailedOrderInQueue;
    // const orderId: string = route.params.id;
    if (orderId && orderId !== NoOrdersId) {
      const foundOrder = orders.find(o => o.id === orderId);
      if (foundOrder) {
        detailedOrder = {
          ...foundOrder,
          orderStatusInfo: OrderStatusService.getStatusInfo(foundOrder.orderStatus),
          isLoading: false,
          isLoadError: false,
          details: {
            ...foundOrder.metadata.orderInfoDto
          },
          statusHistory: []
        };
        return this.apiProvider.fetchOrderHistory(orderId)
          .pipe(
            map(orderHistoryResponse => {
              detailedOrder.statusHistory = orderHistoryResponse.data.statusHistory;
              ctx.setState(
                patch({
                  currentOrder: detailedOrder
                })
              );
              return detailedOrder;
            }),
            take(1)
          );
      } else {
        return this.apiProvider.fetchOrderDetails(orderId)
          .pipe(
            take(1),
            switchMap(orderInfoResponse => {
              detailedOrder = {
                ...orderInfoResponse.data,
                orderStatusInfo: OrderStatusService.getStatusInfo(orderInfoResponse.data.orderStatus),
                isLoading: false,
                isLoadError: false,
                details: orderInfoResponse.data.metadata.orderInfoDto,
                statusHistory: []
              };
              return this.apiProvider.fetchOrderHistory(orderId)
                .pipe(
                  map(orderHistoryResponse => {
                    detailedOrder.statusHistory = orderHistoryResponse.data.statusHistory;
                    ctx.setState(
                      patch({
                        currentOrder: detailedOrder
                      })
                    );
                    return detailedOrder;
                  }),
                  take(1)
                );
            }),
            catchError(() => {
              ctx.patchState({
                currentOrder: null
              });
              return of({
                isLoading: false,
                isLoadError: false,
                details: null,
                statusHistory: []
              } as DetailedOrderInQueue);
            })
          );
      }
    } else {
      ctx.patchState({
        currentOrder: null
      });
      return of({
        isLoading: false,
        isLoadError: false,
        details: null,
        statusHistory: []
      } as DetailedOrderInQueue);
    }
  }

  @Action(SetDeliveryHistory)
  setDeliveryHistory(ctx: StateContext<OrderStateModel>): any {
    const state = ctx.getState();
    const detailedOrder: DetailedOrderInQueue = state.currentOrder;
    this.apiProvider.fetchOrderHistory(detailedOrder.id)
      .pipe(
        debounceTime(3000)
      )
      .subscribe({
        next: (orderHistoryResponse) => {
          const detailedOrderDone: DetailedOrderInQueue = {
            ...detailedOrder,
            statusHistory: orderHistoryResponse.data.statusHistory,
            isLoading: false,
            isLoadError: false
          };
          return ctx.setState(
            patch({
              currentOrder: detailedOrderDone
            })
          );
        },
        error: () => {
          const errorOrder: DetailedOrderInQueue = {
            ...detailedOrder,
            isLoading: false,
            isLoadError: true
          };
          ctx.setState(
            patch({
              currentOrder: errorOrder
            })
          );
        }
      });
  }

  @Action(SetDeliveringCalculates)
  setDeliveringCalculates(ctx: StateContext<OrderStateModel>, {calculates}): void {
    ctx.setState(
      patch({
        deliveryCalculates: calculates
      }));
  }
}
