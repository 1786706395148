import { IOrderInfo } from './orderInfo.model';
import { OrderStatus, OrderStatusInfo } from './orderStatus.model';
import { OrderTransitionalHistory } from './orderTransitionalHistory.model';
import { ICalculateDeliveryModel } from './ICalculateDeliveryModel';

export class OrderInQueue {
  id: string;
  orderStatus: OrderStatus;
  orderStatusInfo: OrderStatusInfo;
  sourceSystem: number;
  targetSystem: number;
  sourceOrderId: string;
  targetOrderId: string;
  sourceCreationTimeUTC: Date;
  targetCreationTimeUTC: Date;
  organizationId: string;
  discoveredAt: Date;
  discoveredAtUTC: number;
  metadata: OrderMetadata;
  masterOrderId: string;
}

export class DetailedOrderInQueue extends OrderInQueue {
  isLoading: boolean;
  isLoadError: boolean;
  details: IOrderInfo | null;
  statusHistory: OrderTransitionalHistory[];
}

export const ORDER_STATUSES: any[] = [
  {key: OrderStatus.NEW, value: 'New', lang: 'STATUS.NEW'},
  {key: OrderStatus.ACCEPTED, value: 'Accepted', lang: 'STATUS.ACCEPTED'},
  {key: OrderStatus.COOKING, value: 'Cooking', lang: 'STATUS.COOKING'},
  {key: OrderStatus.READY_TO_SHIP, value: 'Ready to ship', lang: 'STATUS.READY_TO_SHIP'},
  {key: OrderStatus.SHIPPED, value: 'Shipped', lang: 'STATUS.SHIPPED'},
  {key: OrderStatus.DELIVERED, value: 'Delivered', lang: 'STATUS.DELIVERED'},
  {key: OrderStatus.CLOSED, value: 'Closed', lang: 'STATUS.CLOSED'},
  {key: OrderStatus.CANCELLED, value: 'Cancelled', lang: 'STATUS.CANCELLED'},
  {key: OrderStatus.ANY_ERROR, value: 'Error', lang: 'STATUS.ERRORED'},
];

export interface OrderMetadata {
  orderInfoDto: IOrderInfo;
  sourceHumanOrderId: string;
  targetHumanOrderId: string;
  menuId: string;
  calculateInfo: ICalculateDeliveryModel[];
  tags: OrderTags[];
}

export enum OrderTags {
  'TOO_LATE' = 'TOO_LATE',
  'TOO_FEW_COORDINATES' = 'TOO_FEW_COORDINATES',
}
