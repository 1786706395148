import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { environment } from 'environments/environment';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {
  MenuSchedule,
  MenuScheduleChangeRequestItem,
  MenuScheduleResponseItem, ProductSchedulesRequest,
  ProductSchedulesResponse
} from 'app/models/menu.schedule.model';
import { IBaseResponse } from 'app/models/baseResponse.model';

@Injectable({
  providedIn: 'root'
})
export class MenuScheduleApiService {
  private headers: HttpHeaders = new HttpHeaders({
    'Content-Type': 'application/json',
  });

  constructor(private http: HttpClient) {
  }

  public async getSchedules(menuSectionId: string): Promise<IBaseResponse<MenuScheduleResponseItem[]>> {
    const url = environment.posBaseUrl + `/api/front/menu_schedules/${menuSectionId}`;
    try {
      return await firstValueFrom(this.http.get<IBaseResponse<MenuSchedule[]>>(url));
    }
    catch (exception){
      console.log('exception', exception);
    }
  }

  // return all schedules
  public async changeSchedules(request: MenuScheduleChangeRequestItem[]):
    Promise<IBaseResponse<MenuScheduleResponseItem[]>>
  {
    const url = environment.posBaseUrl + '/api/front/menu_schedules/change';
    try {
      return await firstValueFrom(this.http.post<IBaseResponse<any>>(
        url,
        request,
        { headers: this.headers }));
    }
    catch (exception){
      console.log('exception', exception);
    }
  }

  async getProductSchedules(menuSectionId: string, externalProductId: string):
    Promise<IBaseResponse<ProductSchedulesResponse>>
  {
    const url = environment.posBaseUrl + `/api/front/menu_schedules/${menuSectionId}/${externalProductId}/`;
    try {
      return await firstValueFrom(this.http.get<IBaseResponse<ProductSchedulesResponse>>(url));
    }
    catch (exception){
      console.log('exception', exception);
    }
  }

  public async setSchedules(request: ProductSchedulesRequest)
  {
    const url = environment.posBaseUrl + '/api/front/menu_schedules/product';
    try {
      return await firstValueFrom(this.http.post<IBaseResponse<any>>(
        url,
        request,
        { headers: this.headers }));
    }
    catch (exception){
      console.log('exception', exception);
    }
  }
}
